// custom typefaces
import React from "react"
import { StationProvider } from "./src/context/station-context"
import AudioPlayer from "./src/components/audio-player"
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

export const wrapRootElement = ({ element }) => (
  <StationProvider>
    <div>{element}</div>
  </StationProvider>
)

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return (
    <div {...props}>
      {element}
      <div>
        <AudioPlayer />
      </div>
    </div>
  )
}
